import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

import { AddTournamentComponent } from './add-tournament/add-tournament.component';


@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent implements OnInit {
  myTournaments;
	myTournamentsByHost;
  myTournamentsInfo;
  currentUser;
  myTournamentList;
  changeDetection: ChangeDetectionStrategy.OnPush;

  constructor(public db: AngularFireDatabase, public afAuth: AngularFireAuth, public router:Router )
  {
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      this.myTournaments = db.list('/staging/tournamentsByHost/'+ this.currentUser.uid);
      this.myTournamentsByHost = db.object('/staging/tournamentsByHost/'+ this.currentUser.uid);
      this.myTournamentsInfo = db.list('/staging/tournamentsInfo');
      this.getTournamentsList();
    });
  }

  ngOnInit() {
    // this.afAuth.authState.subscribe(res => {
    //   this.currentUser = res;
    //   this.myTournaments = this.db.list('/staging/tournamentsByHost/'+ this.currentUser.uid);
    //   this.myTournamentsByHost = this.db.object('/staging/tournamentsByHost/'+ this.currentUser.uid);
    //   this.myTournamentsInfo = this.db.list('/staging/tournamentsInfo');
    // });

  }
  ngOnChanges(): void {
    console.debug('change');
  }

  getTournamentsList(){
    this.db.list('/staging/tournamentsByHost/'+ this.currentUser.uid)
    .snapshotChanges()
    .take(1).subscribe((tournaments)=>{
      console.log('new value', tournaments);
        this.myTournamentList=[];
      console.log('Tournaments list', this.myTournamentList);
        tournaments.forEach((tourList)=>{
          this.db.object('/staging/tournamentsInfo/' + tourList['key'] ).snapshotChanges().subscribe(
            (tour)=>{
              // we get the status from TournamentsByHost
              let tournamentData = tour.payload.val()
              this.myTournamentList.push( {id: tour['key'], name: tournamentData['name'], status: tournamentData['status'] } );
            console.log('Tournaments list', this.myTournamentList);
            });
        });
      });
  }

  setTournamentStatus(tournamentId, status){
    console.log('[Tournaments] [setTournamentStatus]  UserId ' + this.currentUser.uid )
    console.log('[Tournaments] [setTournamentStatus]  tournamentId ' + tournamentId )
    this.db.object('/staging/tournamentsByHost/'+ this.currentUser.uid + '/' + tournamentId)
      .set(status)
      .then(
        (res=>{
          console.log('Saved!!! '  + tournamentId);
          this.db.object('/staging/tournamentsInfo/'+ tournamentId).valueChanges().take(1).subscribe(
            (tournament)=>{
              tournament['status'] = status;
              // console.log('turneul de editat', tournament);
              this.db.object('/staging/tournamentsInfo/'+ tournamentId).set(tournament);
            });
          this.getTournamentsList();
        })
        )
      .catch(
        (err)=>{
          alert('Cannot update the status of tournament')
          console.log('[Tournaments] [setTournamentStatus] error ' + err)
        }
      );
  }

  editTournament(tournamentId){
    this.router.navigate(['/edit-tournament/', tournamentId]);
      }

  showMessage(mes){
    alert(mes);
  }

}
