import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-view-question',
  templateUrl: './view-question.component.html',
  styleUrls: ['./view-question.component.css']
})
export class ViewQuestionComponent implements OnInit {
	questionForm : FormGroup;
  checkedValues=[false, false, false, false];
  question;
  questionsDb;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef <ViewQuestionComponent>, fb: FormBuilder, db: AngularFireDatabase) {
    this.questionForm = fb.group({
      'title': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
      'q1Title': [null, Validators.required],
      'q1Score': [null],
      'q2Title': [null, Validators.required],
      'q2Score': [null],
      'q3Title': [null, Validators.required],
      'q3Score': [null],
      'q4Title': [null, Validators.required],
      'q4Score': [null]
      })
    this.questionsDb = db;
  }

  ngOnInit() {
  	var questionId = this.data;
    
    var questionsDetailed = this.questionsDb.object('/staging/qos/' + questionId).valueChanges()
      .subscribe(
        (res)=>{ this.question = res; console.log('question: ' , this.question)},
        (err)=>{console.log('Error', err)}
        )
  }

}
