import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/take';
// custom validators
import { IntegerNumbersOnlyValidator } from './../../validators/integer-numbers-only';
import { IntegerPositiveNumbersOnlyValidator } from './../../validators/integer-positive-numbers-only';
import { NumbersOnlyValidator } from './../../validators/numbers-only';
import { AlphanumericValidator } from './../../validators/alphanumeric';
import { NoWhitespaceValidator } from './../../validators/no-whitespace';

@Component({
selector: 'add-quiz',
templateUrl: './add-quiz.component.html',
styleUrls: ['./add-quiz.component.css'],
})

export class AddQuizComponent implements OnInit {

addQuizForm : FormGroup;
tournamentId;
currentUser;
quiz = {
  kreator: '',
  title: '',
  status: 'unpublished',
  lastModified: null,
  config: {
    fee: '',
    noOfRounds: 6,
    questionsPerRound : 3,
  },
  tournamentPlay: [
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    },


  ]
};
tmp;
categories;
subcategories =[ [], [], [], [], [], [] ];
questions =[ [], [], [], [], [], [] ];
blankQuestions =[ { questionId: '', questionTitle: '' }, { questionId: '', questionTitle: '' }, { questionId: '', questionTitle: '' } ];
blankRound =
    {
      category:'',
      subcategory: '',
      questions:[
        { questionId: 0, title: ''},
        { questionId: 0, title: ''},
        { questionId: 0, title: ''}
      ]
    };

  constructor( fb: FormBuilder, public db: AngularFireDatabase, public afAuth: AngularFireAuth, private route: ActivatedRoute,
private location: Location, private http: HttpClient) {
    this.addQuizForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      // We can use more than one validator per field. If we want to use more than one validator we have to wrap our array of validators with a Validators.compose function. Here we are using a required, minimum length and maximum length validator.
      'quizTitle': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      'quizFee': [null, Validators.compose([Validators.required, NoWhitespaceValidator, IntegerPositiveNumbersOnlyValidator])],
      'quizStatus': [null, Validators.compose([Validators.required])]
    });


}

  ngOnInit() {
  this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      // get category from route params
      // this.route.params
      // .subscribe(params => {this.tournamentId = params['id']});// remove the first character from category name (-)
      // 	// get tournament details
      // 	this.db.object('/staging/tournamentsInfo'+  '/' + this.tournamentId)
      // 	.subscribe(
      // 		(res)=>{
      // 			this.quiz = res;
      // 		});
      });

  this.db.list('/staging/categories')
      .snapshotChanges()
      .subscribe(
        (categ)=>{
          this.categories = []
          categ.forEach(
            (categ)=>{
              let temp = categ.payload.val()
              temp['$key'] = categ['key']
              this.categories.push(temp)
            }
          )

        }
      )
}

categoryChange(roundNo){
  console.log('Category Changed!')
  // Special situation when "My subcategories" is selected
  if (this.quiz.tournamentPlay[roundNo].category === '-Kpu7bNSGGA2lxyOp8it') {
    this.db.list('/staging/subcategories/-'+ this.currentUser.uid)
        .valueChanges()
        .subscribe(
          (res)=>{
            this.subcategories[roundNo] = res;
            this.quiz.tournamentPlay[roundNo].subcategory='';
          });
  }
  // All other Categories
  else{
    this.db.list('/staging/subcategories/'+ this.quiz.tournamentPlay[roundNo].category)
        .snapshotChanges()
        .subscribe(
          (res)=>{
            this.subcategories[roundNo] = []
            res.forEach(
            (categ)=>{
              let temp = categ.payload.val()
              temp['$key'] = categ['key']
              this.subcategories[roundNo].push(temp)
            }
          )

          this.quiz.tournamentPlay[roundNo].subcategory='';
          // console.log('NEW this.quiz.tournamentPlay[roundNo].subcategory', this.quiz.tournamentPlay[roundNo].subcategory);
          });
    }

    // reset all questions in round
      this.quiz.tournamentPlay[roundNo].questions =
      [
      { questionId: 0, title: ''},
      { questionId: 0, title: ''},
      { questionId: 0, title: ''}
    ];
  this.subcategoryChange(roundNo);
}

subcategoryChange(roundNo){
  // console.log('[Add Quiz] [subcategoryChange] question ', this.quiz.tournamentPlay[roundNo].subcategory)
  if ( this.quiz.tournamentPlay[roundNo].subcategory ){
    this.db.list('/staging/questionsBySubcategory/'+ this.quiz.tournamentPlay[roundNo].subcategory)
      .snapshotChanges()
      .subscribe(
        (res)=>{
          this.questions[roundNo] = []
            res.forEach(
            (question)=>{
              let temp ={}
              temp['title'] = question.payload.val()
              temp['$key'] = question['key']
              this.questions[roundNo].push(temp)
              // console.log('[Add Quiz] [subcategoryChange] question ', temp)
            })
            // console.log('[Add Quiz] [subcategoryChange] ALL questions ', this.questions[roundNo])

          // this.questions[roundNo] = res;


          // reset all questions in round
          this.quiz.tournamentPlay[roundNo].questions = [
            { questionId: 0, title: ''},
            { questionId: 0, title: ''},
            { questionId: 0, title: ''}
          ];
        });
  }
}

getQuestion(roundNo, questionNo){
  // console.log('GetQuestion() this.quiz.tournamentPlay[roundNo].subcategory', this.quiz.tournamentPlay[roundNo].subcategory);
  let noOfQuestions = this.questions[roundNo].length - 1;
  let randomQuestionNo = Math.floor((Math.random() * noOfQuestions) );
  // check if question already exists and retry if necessary
  if (this.questionExists( this.questions[roundNo][randomQuestionNo].$key)) {
    // console.log('Question already exists ' + this.questions[roundNo][randomQuestionNo].$key + ' trying again! ')
    this.getQuestion(roundNo, questionNo);
  }
  else{
    // console.log('Round: ' + roundNo + ' Question: ' + questionNo , this.questions[roundNo][randomQuestionNo]);
    this.quiz.tournamentPlay[roundNo].questions[questionNo].questionId = this.questions[roundNo][randomQuestionNo].$key;
    this.quiz.tournamentPlay[roundNo].questions[questionNo].title = this.questions[roundNo][randomQuestionNo].title;
  }
}

questionExists(question) {
  let exists = false;
  this.quiz.tournamentPlay.forEach(
    (round)=>{
      round.questions.forEach(
        (q)=>{
          if (q.questionId == question) {
            exists = true;
          }
        }
        )
    }
    )
  return exists;
}

goBack(){
this.location.back();
}

saveData() {
  if (!this.checkQuiz()) {
    alert('Please complete all questions');
    return;
  }

    this.quiz.lastModified = new Date().toString();
    let dataToSave={
      title: this.quiz.title,
      kreator: this.currentUser.uid,
      status: this.quiz.status,
      lastModified : this.quiz.lastModified,
      config: {
        fee: this.quiz.config.fee,
        noOfRounds : 6,
        questionsPerRound :3
      },
      tournamentPlay: 0
    }
    let allgood = true;
    let newRoundId;
    let newQuizID;
    // save Info in Quiz Library
    this.db.list('/staging/quizLibrary')
      .push(dataToSave)
        .then(
          (savedQuiz)=>{
            newQuizID = savedQuiz.key;
// console.log('New quiz: ', newQuizID);
            if (!newQuizID) { allgood = false }
            // go through every round
            this.quiz.tournamentPlay.forEach(
              (round)=>{
                // Create empty round
                this.db.list('/staging/quizLibrary/' + savedQuiz.key + '/tournamentPlay')
                .push(
                  {	category: round.category,
                    subcategory: round.subcategory
                  }
                  )
                  .then(
                  (res)=>{
                    newRoundId = res.key;
// console.log('Empty Round saved: ', res.key);
                    //  go through every question in round
                    round.questions.forEach(
                      (q)=>{
                        this.saveQuestionInRound(newQuizID, newRoundId, q.questionId);
                      });
                  });
              });

            // save Info in Quiz Library
          let newQuizByKreator={
            quizId: newQuizID,
            quizStatus: this.quiz.status,
            quizTitle: this.quiz.title,
            quizModified: this.quiz.lastModified
          };

            this.db.list('/staging/quizByKreator/' + this.currentUser.uid)
            .push(newQuizByKreator)
            .then(
              (res)=>{
                if (!allgood) { alert('Quiz was not saved correctly'); }
                else{ alert('Quiz saved');
                  // console.log('newQuizByKreator', newQuizByKreator);
                  // console.log('res', res);
                  this.location.back();
                }
            })
            .catch(
              (err)=>{
                alert('Error saving Quiz by Kreator');
                console.log('Error saving Quiz by Kreator', err);
              }
            )
          });


}

saveQuestionInRound(quizId, roundId, questionId){
// console.log(' Save Question ' + questionId + ' in  round' + roundId);
  this.db.object('/staging/qos/' + questionId)
  .valueChanges()
  .take(1)
  .subscribe(
    (question)=>{
      let questionToSave={
        title: question['title']
      }
      this.db.list('/staging/quizLibrary/' + quizId + '/tournamentPlay/' + roundId + '/questions')
      .push(questionToSave)
      .then(
        (rez)=>{
          //  Set Question options one by one in order to get generated ID's
          question['options'].forEach(
            (option)=>{
              this.db.list('/staging/quizLibrary/' + quizId + '/tournamentPlay/' + roundId + '/questions/' + rez.key +'/options/')
              .push(option)
              .then(
                (res)=>{
// console.log('/staging/quizLibrary/' + quizId + '/tournamentPlay/' + roundId + '/questions/' + rez.key);
                })
            }

            );

        });

    });
}

checkQuiz() {
  var res = true;
  [0,1,2,3,4,5].forEach(
    (roundNo)=>{
      [0,1,2].forEach(
        (questionNo)=>{
          if (!this.quiz.tournamentPlay[roundNo].questions[questionNo].title) {
            res = false;
          }
        });
    });
  return res;
}

public NoWhitespaceValidator(control: FormControl) {
  if (/\s/.test(control.value)) {
      // It has any kind of whitespace
      return { 'whitespace': true };
  }
  else{
    return null;
  }
}

public AlphanumericValidator(control: FormControl) {
  if (/^[\w\-\s]+$/.test(control.value)) {
      // It contains other characters than letters and numbers
      return null;
  }
  else{
    return { 'alphanumeric': true };
  }
}

}
