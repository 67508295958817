import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent{
		user: Observable<firebase.User>;
	currentUserId;

  constructor(public afAuth: AngularFireAuth, public router: Router, bd: AngularFireDatabase) { 
  	this.user = afAuth.authState;
    this.afAuth.authState.subscribe(res => {
          if (res) {
            this.currentUserId = res.uid;
            bd.object('/staging/users/' + this.currentUserId + '/authorized')
            .valueChanges()
            .subscribe( rezultat =>{
              if (rezultat === 'authorized') {
                console.log('you are authorized. go ahead');
              }
              else{
              	this.router.navigate(['/login']);
                alert('You are not authorized for this! Please contact the administrator')
              }
            });
          }
        });
}

  ngOnInit() {
  }

}
