import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {MatDialog, MatDialogRef} from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { AddSubcategoryComponent } from '../add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from '../edit-subcategory/edit-subcategory.component';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css']
})
export class SubcategoriesComponent implements OnInit {
	subcategories;
  selectedOption: string;
  currentUser;
  database;

  constructor(db: AngularFireDatabase, public afAuth: AngularFireAuth, public dialog: MatDialog ) 
  {
    this.database = db
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      db.list('/staging/subcategories/-'+ this.currentUser.uid).snapshotChanges().subscribe(
        (rez)=>{
          this.subcategories = []
          rez.forEach(
            (sub)=>{
              let temp = sub.payload.val()
              this.subcategories.push({ $key: sub.key, subcategoryName: temp['subcategoryName'] })
            }
          )
          // console.log('[Subcategories] [constructor] Subcategories ', this.subcategories)
        }
      )
      });
  }

  ngOnInit() { 
  }

  addSubcategory() {
    let dialogRef = this.dialog.open(AddSubcategoryComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedOption = result;
        let newSubcategory = { subcategoryName: result }
        // this.subcategories.push(newSubcategory);
        this.database.list('/staging/subcategories/-'+ this.currentUser.uid).push(newSubcategory)
      }
    });
  }

  editSubcategory(subcategory) {
    let dialogRef = this.dialog.open(EditSubcategoryComponent, {data: subcategory});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        subcategory.subcategoryName = result;
        this.database.list('/staging/subcategories/-'+ this.currentUser.uid).update( subcategory.$key, {subcategoryName: subcategory.subcategoryName} );
      }
    });
  }

}
