import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
// custom validators
import { IntegerNumbersOnlyValidator } from './../../validators/integer-numbers-only';
import { IntegerPositiveNumbersOnlyValidator } from './../../validators/integer-positive-numbers-only';
import { NumbersOnlyValidator } from './../../validators/numbers-only';
import { AlphanumericValidator } from './../../validators/alphanumeric';
import { NoWhitespaceValidator } from './../../validators/no-whitespace';

@Component({
  selector: 'edit-tournament',
  templateUrl: './edit-tournament.component.html',
  styleUrls: ['./edit-tournament.component.css']
})
export class EditTournamentComponent implements OnInit {
editForm : FormGroup;
tournamentId;
myQuizList;
currentUser;
tournament;
// tournament = {
// 	name: '',
// 	description: '',
// 	host: '',
// 	date: 0,
// 	config :{
// 		fee: '',
// 		image:'',
// 		questionTimeOut: '',
// 		roundTimeOut: ''
// 	},
// 	quiz: {
// 		id: '',
// 		lastLoaded: ''
// 	}
// };
tempdate;

  constructor( fb: FormBuilder, public db: AngularFireDatabase, public afAuth: AngularFireAuth, private route: ActivatedRoute,
private location: Location, private http: HttpClient) {
    this.editForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      // We can use more than one validator per field. If we want to use more than one validator we have to wrap our array of validators with a Validators.compose function. Here we are using a required, minimum length and maximum length validator.
      'tournamentName': [null, Validators.compose([Validators.required, Validators.minLength(2) ])],
      'tournamentDescription': [null, Validators.compose([Validators.required, Validators.minLength(5)])],
      'tournamentConfigFee': [null, Validators.compose([Validators.required, NoWhitespaceValidator, IntegerPositiveNumbersOnlyValidator])],
      'selectedQuiz': [null, Validators.compose([Validators.required])],
      'winnerPercentage': [null, Validators.compose([Validators.required])],
      'date': [null, Validators.compose([Validators.required])],
    });


}

ngOnInit() {
  this.afAuth.authState.subscribe(res => {
      this.currentUser = res;

      // get category from route params
      this.route.params
      .subscribe(params => {
        this.tournamentId = params['id'];
        // get tournament details
        this.db.object('/staging/tournamentsInfo'+  '/' + this.tournamentId)
        .valueChanges()
        .subscribe(
          (res)=>{
            console.log('Incoming tournament ', res);
            if (!res['date']) {
              this.tempdate=new Date();
            }
            else{
              this.tempdate = new Date(res['date']);
            }

            this.tournament = res;
          });
      });

      // get quiz list
      this.db.list('/staging/quizByKreator/' + this.currentUser.uid)
      .valueChanges()
      .subscribe(
        (res)=>{
          this.myQuizList = res;
        });

      });
}

goBack(){
this.location.back();
}

saveData() {
  this.tournament.date = this.tempdate.getTime();
// console.log('saving tournament ', this.tournament);
  this.db.object('/staging/tournamentsInfo'+  '/' + this.tournamentId)
  .update(this.tournament)
  .then(
    (res)=>{
      alert('Tournament data saved');
      this.location.back();
    })

}

onUploadFinished($event){
this.http
          .post('./files/', $event.FileHolder)
          .subscribe();

console.log('upload finished', $event);
}

public NoWhitespaceValidator(control: FormControl) {
  if (/\s/.test(control.value)) {
      // It has any kind of whitespace
      return { 'whitespace': true };
  }
  else{
    return null;
  }
}

public AlphanumericValidator(control: FormControl) {
  if (/^[\w\-\s]+$/.test(control.value)) {
      // It contains other characters than letters and numbers
      return null;
  }
  else{
    return { 'alphanumeric': true };
  }
}

}
