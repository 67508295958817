import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireDatabase, snapshotChanges } from '@angular/fire/database';
import {MatDialog, MatDialogRef} from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';

import { AddQuestionComponent } from '../add-question/add-question.component';
import { EditQuestionComponent } from '../edit-question/edit-question.component';
import { ViewQuestionComponent } from '../view-question/view-question.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
	questions;
  questionsDetailed;
  questionTitle;
  selectedOption: string;
  currentUser;
  currentCategory;

  constructor(db: AngularFireDatabase, public afAuth: AngularFireAuth, public dialog: MatDialog, private route: ActivatedRoute,
  private location: Location ) 
  {
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      // get category from route params
      this.route.params
      .subscribe(params => this.currentCategory = params['category'].substr(1));// remove the first character from category name (-)
      // get the list of questions in category
      db.list('/staging/questionsBySubcategory/-'+ this.currentCategory).snapshotChanges().subscribe(
        (res)=>{
          this.questions = []
          res.forEach(
            (q)=>{
              this.questions.push( { $key: q.key, $value: q.payload.val()} )
            }
          )
          console.log('[Questions] [constructor] questions ', this.questions );
        }
      )
      this.questionTitle = db.object('/staging/questionsBySubcategory/-'+ this.currentCategory);
      this.questionsDetailed = db.list('/staging/qos/');

      
      });
  }

  ngOnInit(): void {
  }

  addQuestion() {
    let dialogRef = this.dialog.open(AddQuestionComponent);
    dialogRef.afterClosed().subscribe(newQuestion => {
      console.log('dialog closed=>', newQuestion);
      if (newQuestion) {
        this.questionsDetailed.push(newQuestion)
          .then((rez)=>{
            console.log('Saved Question', rez);
            var cheie = rez.path.o[rez.path.o.length - 1];
            console.log( 'Salvam si '+ cheie + ' : ' + newQuestion.title, newTitle );
            var newTitle ={};
            newTitle[cheie] = newQuestion.title ;
            console.log('newTitle', newTitle);
            
            this.questionTitle.update(newTitle)
             .then( (raspuns) => {
               alert('Question saved');
               console.log('raspuns', raspuns) 
             } )
             .catch( (err) => {
               alert('Unable to save question');
               console.log('err', err)
             } )
          })
          .catch((err)=>{console.log('Error saving question', err)})
      }
    });
  }

  viewQuestion(question) {
    let dialogRef = this.dialog.open(ViewQuestionComponent, {data: question});
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialog closed=>', result);
      if (result) {
        console.log('Saving edited question', result);
        this.questions.update( question.$key, result );
      }
    });
   }

  editQuestion(question) {
    let dialogRef = this.dialog.open(EditQuestionComponent, {data: question});
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialog closed=>', result);
      if (result) {
        var newTitle ={};
            newTitle[result.id] = result.title ;
        this.questionTitle.update(newTitle);
        this.questionsDetailed.update(result.id, result);
      }
    });
  }

}
