import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.css']
})
export class EditQuestionComponent implements OnInit {
	questionForm : FormGroup;
  checkedValues=[false, false, false, false];
  questionId;
  question;
  db;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef <EditQuestionComponent>, fb: FormBuilder, angularFireDatabase: AngularFireDatabase,) {
    this.questionForm = fb.group({
      'title': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
      'q1Title': [null, Validators.required],
      'q1Score': [null],
      'q2Title': [null, Validators.required],
      'q2Score': [null],
      'q3Title': [null, Validators.required],
      'q3Score': [null],
      'q4Title': [null, Validators.required],
      'q4Score': [null]
      });
    this.db = angularFireDatabase;
  }

  ngOnInit() {
  	console.log('Edit question', this.data);
    this.questionId = this.data;
    this.db.object('/staging/qos/'+ this.data).valueChanges().subscribe(
      (res)=>{
        this.question = res;
        console.log('question', res);
        this.checkedValues[0] = this.question.options[0].score == 1 ? true : false;
        this.checkedValues[1] = this.question.options[1].score == 1 ? true : false;
        this.checkedValues[2] = this.question.options[2].score == 1 ? true : false;
        this.checkedValues[3] = this.question.options[3].score == 1 ? true : false;

        this.questionForm.setValue({
          'title': this.question.title,
          'q1Title': this.question.options[0].title,
          'q1Score': this.checkedValues[0],
          'q2Title': this.question.options[1].title,
          'q2Score': this.checkedValues[1],
          'q3Title': this.question.options[2].title,
          'q3Score': this.checkedValues[2],
          'q4Title': this.question.options[3].title,
          'q4Score': this.checkedValues[3]
        })
      });
  }

  saveData() {
  	let res = this.questionForm.value;
    console.log('Saving edited data', this.questionForm.value);
    let ds;
    res.q1Score = res.q1Score ? 1 : 0;
    res.q2Score = res.q2Score ? 1 : 0;
    res.q3Score = res.q3Score ? 1 : 0;
    res.q4Score = res.q4Score ? 1 : 0;

    ds = {'id': this.questionId, 
          'title': res.title, 
            options:[{'title': res.q1Title, 'score': res.q1Score},
                      {'title': res.q2Title, 'score': res.q2Score},
                      {'title': res.q3Title, 'score': res.q3Score},
                      {'title': res.q4Title, 'score': res.q4Score}
                      ]};
    this.dialogRef.close(ds);
	}

  checkboxChecked(nr){
    console.log('checked ', nr);
    for (var i = 0; i <= 3; i++) {
      if (i != nr){
        this.checkedValues[i]=false;
      }
    }
  }

}
