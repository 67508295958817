import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireDatabase} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
// custom validators
import { IntegerNumbersOnlyValidator } from './../../validators/integer-numbers-only';
import { IntegerPositiveNumbersOnlyValidator } from './../../validators/integer-positive-numbers-only';
import { NumbersOnlyValidator } from './../../validators/numbers-only';
import { AlphanumericValidator } from './../../validators/alphanumeric';
import { NoWhitespaceValidator } from './../../validators/no-whitespace';

@Component({
  selector: 'app-add-tournament',
  templateUrl: './add-tournament.component.html',
  styleUrls: ['./add-tournament.component.css']
})
export class AddTournamentComponent implements OnInit {
currentUser;
myQuizList;
addTournamentForm : FormGroup;
tournament = {
  name: '',
  description: '',
  host: '',
  winnerPercentage: null,
  date: 0,
  status: 'unpublished',
  config :{
    fee: '',
    image:'',
    questionTimeOut: '15',
    roundTimeOut: '10'
  },
  quiz: {
    id: '',
    lastLoaded: ''
  }
};
myTournamentsByHost;
myTournamentsInfo;
tempdate = new Date();

  constructor(public db: AngularFireDatabase, fb: FormBuilder, public afAuth: AngularFireAuth, public location: Location) {
    this.addTournamentForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      // We can use more than one validator per field. If we want to use more than one validator we have to wrap our array of validators with a Validators.compose function. Here we are using a required, minimum length and maximum length validator.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(2) ])],
      'description': [null, Validators.compose([Validators.required, Validators.minLength(5)])],
      'fee': [null, Validators.compose([Validators.required, NoWhitespaceValidator, IntegerPositiveNumbersOnlyValidator])],
      // 'questionTimeOut': [null, Validators.compose([Validators.required, NoWhitespaceValidator, IntegerPositiveNumbersOnlyValidator])],
      // 'roundTimeOut': [null, Validators.compose([Validators.required, NoWhitespaceValidator, IntegerPositiveNumbersOnlyValidator])],
      'winnerPercentage': [null, Validators.compose([Validators.required])],
      'date': [null, Validators.compose([Validators.required])],
      'selectedQuiz': [null, Validators.compose([Validators.required])]
    })
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      this.myTournamentsByHost = db.object('/staging/tournamentsByHost/'+ this.currentUser.uid);
      this.myTournamentsInfo = db.list('/staging/tournamentsInfo');
    });
}

ngOnInit() {
  this.afAuth.authState.subscribe(user => {
    this.currentUser = user;
    this.db.list('/staging/quizByKreator/' + this.currentUser.uid)
    .valueChanges()
    .subscribe(
      (res)=>{
        this.myQuizList=[]
        res.forEach(
          (quiz, key) => {
            if (quiz['quizStatus'] !== 'unpublished') {
              this.myQuizList.push(quiz);
            }
        });
      }
      )
    });
}


saveData() {

this.tournament.quiz.lastLoaded = new Date().toString();

  this.tournament.host = this.currentUser.uid;
  this.tournament.date = this.tempdate.getTime();

  // save new tournament in Tournaments Info
  this.myTournamentsInfo.push(this.tournament)
    .then((res) => {
      // get new tournament's id
      let newTournamentId = res.key;
      let newTournamentByHost ={};
      newTournamentByHost[newTournamentId] = 'unpublished';
      // use UPDATE to save info, in order to be able to set custom id (object only)
      this.myTournamentsByHost
      .update( newTournamentByHost )
      .then(
        ()=>{
          this.saveTournamentsPlayInfo(newTournamentId, this.tournament.quiz.id);
          alert('Tournament data saved');
      this.location.back();
          }
      )
      .catch( (err) => {
          console.log('Unable to save Tournament By Host', err)
        });
    })
    .catch( (err) => {
          alert('Unable to save Tournament Info');
          console.log('err', err)
    } );
}



saveTournamentsPlayInfo(tournamentId, quizId){
  this.db.object('/staging/quizLibrary/'+quizId+'/tournamentPlay')
  .valueChanges()
  .subscribe(
    (quizData)=>{
      let tempData = {};
console.log('quizId', quizId);
console.log('TournamentsInfo ID: ', tournamentId);

      this.db.object('/staging/tournamentsPlay/'+ tournamentId).set(quizData)
      .then(
        ()=>{ console.log('saved tournamentPlay with ID ' + tournamentId) }
      );
    }
  );
}





}
