import { AbstractControl } from '@angular/forms';

export function IntegerPositiveNumbersOnlyValidator(control: AbstractControl) {
	if (/^\+?(0|[1-9]\d*)$/.test(control.value)) {
	    // It contains other characters than valid numbers
	    return null;
	}
	else{
		return { 'integersonly': true };
	}
}