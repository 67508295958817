import { Component, OnInit } from '@angular/core';
import { HttpClientModule, HttpClient, HttpResponse } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';


@Component({
  selector: 'transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  currentUser;
  myTransactions;
  serverError;

  constructor(public afAuth: AngularFireAuth, private http: HttpClient ) 
  {
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
    });
  }

  ngOnInit() { 

    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      console.log('Userid: ');
      this.http.get('https://zenithbattle.herokuapp.com/getTransactions?userId='+this.currentUser.uid)
        .subscribe(
          (res)=>{
            let result = res;
            let transactions =[];

            Object.keys(result).forEach(function(key) {
                transactions.push(result[key]);
            });

            // remove last element since it's something called "status" and we don't need it
            this.myTransactions=transactions.slice(0,-1); 
          },
          (err)=>{this.serverError = "Unable to retrieve User Data! "}
          )
    });
  
  }

}
