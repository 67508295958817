import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
	userForm : FormGroup;
  currentUserId;
  dbUser;
  currentUser;
  db;
  newPassword;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(fb: FormBuilder, angularFireDatabase: AngularFireDatabase, public afAuth: AngularFireAuth, public afStorage: AngularFireStorage
    ) {
    this.db = angularFireDatabase;
    this.afAuth.authState.subscribe(res => {
      console.log('My profile] [ngOnInit]', res);
      if (res) {
        this.currentUserId = res.uid;
        this.dbUser = this.db.object('/staging/users/' + res.uid);
        this.dbUser.snapshotChanges()
          .subscribe(user => {
            this.currentUser = user.payload.val();
            console.log('My profile] [ngOnInit] this.currentUser', this.currentUser);
          });
      }
      else {
        console.log('!!!user not logged in!!!!');
      }
  });

  this.userForm = fb.group({
    'displayName': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
    'gamerTag': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
    'organizationName': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
    'address': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
    'organizationNumber': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
    'contactPerson': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
    'phoneNumber': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
    'interest': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
    'email': [null, Validators.compose([Validators.email])]
    });
  }

  ngOnInit() {

  }

  saveData() {
    console.log('[My profile] [saveData] this.dbUser ', this.dbUser )
    console.log('[My profile] [saveData] this.currentUser ', this.currentUser )

    this.dbUser.update(this.currentUser)
    .then(
      (res)=>{
        alert('User data saved');
        console.log('[My profile] [saveData] Updated data ', this.dbUser )
      }
    )
    .catch( (err) => {
           alert('Unable to save user data');
           console.log('err', err)
         } );
    //   .then( (raspuns) => {
    //      alert('User data saved');
    //      // console.log('raspuns', raspuns)
    //    } )
    //    .catch( (err) => {
    //      alert('Unable to save user data');
    //      console.log('err', err)
    //    } );
	}

  changePassword(){
    let authService = this.afAuth.auth;
    let user = this.afAuth.auth.currentUser;

    user.updatePassword(this.newPassword)
      .then(function() {
          alert('Password changed succesfully. Please login again!');
          authService.signOut();

      })
      .catch(function(error) {
        alert('Unable to change password. ' + error.message);
        console.log('Unable to change password', error);
      });
  }

  public NoWhitespaceValidator(control: FormControl) {
    if (/\s/.test(control.value)) {
        // It has any kind of whitespace
        return { 'whitespace': true };
    }
    else{
      return null;
    }
  }

  uploadImage(event){
    console.log('[My profile] [uploadImage] Upload file' , event)


    const file = event.target.files[0];
    const filePath = 'zenith';
    const fileRef = this.afStorage.ref(filePath);
    const task = this.afStorage.upload(filePath, file);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        task.snapshotChanges().pipe(
            finalize(() => {
              let imageObservable = fileRef.getDownloadURL()
              imageObservable.subscribe(
                (r)=>{
                  if (r){
                    this.currentUser.profileImage = r
                    this.saveData();
                  }
                }
              )
            } )
         )
        .subscribe()

  //   // this.currentUser['avatar'] = event;

  // this.afStorage.upload('', event.target.files[0])
  // .snapshotChanges()
  // .subscribe(
  //   (res)=>{
  //     this.uploading = true;
  //   },
  //   (err)=>{
  //     console.log('[Settings] [uploadImage] Unable to save file ', err)
  //   },
  //   ()=>{
  //     //when upload is  finished
  //     ref
  //   }
  // )



	}

}
