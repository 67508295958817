import { AbstractControl } from '@angular/forms';

export function NoWhitespaceValidator(control: AbstractControl) {
	if (/\s/.test(control.value)) {
		    // It has any kind of whitespace
		    return { 'whitespace': true };
		}
		else{
			return null;
		}
}