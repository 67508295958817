// Imports

import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { QuestionsComponent } from './questions/questions.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { AddTournamentComponent } from './tournaments/add-tournament/add-tournament.component';
import { EditTournamentComponent } from './tournaments/edit-tournament/edit-tournament.component';
import { QuizesComponent } from './quizes/quizes.component';
import { AddQuizComponent } from './quizes/add-quiz/add-quiz.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { TransactionsComponent } from './transactions/transactions.component';


// Route Configuration
export const routes: Routes = [
	{ path: '', component: DashboardComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'login', component: LoginComponent },
    { path: 'subcategories', component: SubcategoriesComponent },
    { path: 'tournaments', component: TournamentsComponent },
    { path: 'add-tournament', component: AddTournamentComponent },
    { path: 'edit-tournament/:id', component: EditTournamentComponent },
    { path: 'quizes', component: QuizesComponent },
    { path: 'add-quiz', component: AddQuizComponent },
    { path: 'questions/:category', component: QuestionsComponent },
    { path: 'my-profile', component: MyProfileComponent },
    { path: 'transactions', component: TransactionsComponent },
];


export const routing: ModuleWithProviders = RouterModule.forRoot(routes);