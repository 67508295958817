import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operator/map';
import { debounceTime } from 'rxjs/operator/debounceTime';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  dbUser;
  user: Observable<firebase.User>;
  dbUsers;
  currentUserId;
  currentUser = {gamerTag:'', profileImage:''};
  bd;
  email;
  password;


  constructor(public afAuth: AngularFireAuth, private router: Router, db: AngularFireDatabase,) {
    this.user = afAuth.authState;
    this.bd = db;
    this.afAuth.authState.subscribe(res => {
        if (res) {
          this.currentUserId = res.uid;
          console.log('this.currentUserId ', this.currentUserId);
        }
      },
      (error)=>{
        console.log('error ', error);
      });
  }

  loginWithCredentials(){
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)
      .then(
        (res) => {
          console.log("[Login Component] [loginWithCredentials] logged in ", res)
          // this.afAuth.authState.subscribe(res => {
            // if (res) {
              this.currentUserId = res.user.uid;
              this.bd.object('/staging/users/' + this.currentUserId + '/authorized')
              .valueChanges()
              .subscribe( rezultat =>{
                console.log("[login] [loginWithCredentials]", rezultat)
                console.log("[login] [loginWithCredentials]", typeof rezultat)
                if (rezultat === 'authorized') {
                  this.router.navigate(['/dashboard']);
                }
                else{
                  alert('You are not authorized for this! Please contact the administrator ' + rezultat )
                }
              });
            // }
          // })
        }, 
        (err) => {
        alert('Invalid Credentials');
        console.log('Error ', err);
        }
      );
  }

  loginWithFacebook() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
    	.then(
	      () => {
        this.afAuth.authState.subscribe(res => {
          if (res) {
            this.currentUserId = res.uid;
            this.bd.object('/staging/users/' + this.currentUserId + '/authorized')
            .subscribe( rezultat =>{
              if (rezultat.$value === 'authorized') {
                this.router.navigate(['/dashboard']);
              }
              else{
                alert('You are not authorized for this! Please contact the administrator')
              }
            });
          }
        })
      });
  }

  loginWithGoogle() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(
        () => {
          this.afAuth.authState.subscribe(res => {
            if (res) {
              this.currentUserId = res.uid;
              this.bd.object('/staging/users/' + this.currentUserId + '/authorized')
              .subscribe( rezultat =>{
                if (rezultat.$value === 'authorized') {
                  this.router.navigate(['/dashboard']);
                }
                else{
                  alert('You are not authorized for this! Please contact the administrator')
                }
              });
            }
          })
        }, 
        (err) => {
        alert('Invalid Credentials');
        console.log('Error ', err);
        }
      );
  }
  ngOnInit() {
  }

}
